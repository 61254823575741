import React, { useEffect, useState } from "react";
import PersonalTraining from "../../../Images And Logos/GymPng/PersonalTraining.png";
import {
  Content,
  Divider,
  Header,
  HeaderOrangeText,
  OuterDiv,
  SidePngPersonalTraining,
  TextBox,
} from "../../../Elements/Gym/PictureAndText";

const PictureAndText = () => {
  return (
    <div>
      <OuterDiv>
        <TextBox>
          <Content>
            <Header>
              אימונים אישיים
              <br />
              <HeaderOrangeText> פורצים גבולות ביחד</HeaderOrangeText>
            </Header>
            <br />
            המטרה שלנו ב Mount 10 שיהיה לכם מקום פתוח וחיובי, עם אווירה משפחתית
            אכפתית ותומכת. אנו כאן בשביל אנשים שעושים ספורט בכל יום, ואנשים שלא
            עשו ספורט אף פעם. המטרה שלנו היא לעזור לכם לשפר את איכות החיים,
            להגיע למצב בריאותי טוב, להגיע להישגים שהצבתם לעצמכם בספורט, לאתגר
            אתכם ולעזור לכם להנות בדרך.
            <br />
            <br />
            -הכאב שאתה מרגיש היום, זה יהיה הכוח שלך למחר. {"\r\n"}-להקיף את עצמך
            עם אנשים וסביבה שתיתן לך להרגיש שהכל אפשרי.{"\r\n"} -כל התקדמות טובה
            באה מחוסר נוחות.{"\r\n"} -החלומות יכולים להתגשם אם יש לך את האומץ
            לרדוף אחריהם.
          </Content>
        </TextBox>
        <Divider></Divider>
        <SidePngPersonalTraining
          style={{ backgroundImage: `url(${PersonalTraining})` }}
        ></SidePngPersonalTraining>
      </OuterDiv>
    </div>
  );
};

export default PictureAndText;
