import React from "react";
import Navbar from "./NavbarAndHeader/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./HomePage";
import About from "../About/AboutUs";
import Gym from "../Gym/Gym";
import Lessons from "../Lessons/Lessons";
import { MainCompDiv } from "../../Elements/Main/NavbarAndHeaderElements";
import ArticlePage from "../Articles/ArticlePage";
import Footer from "./Footer/Footer";
import Facilities from "../CenterFacilities/Facilities";
import ArticlesPage from "../Articles/ArticlesCards";
import Staff from "../Our Staff/Staff";
import { useEffect } from "react";

const Index = () => {
  useEffect(() => {}, []);

  return (
    <MainCompDiv>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about_us" component={About} />
          <Route path="/staff" component={Staff} />
          <Route path="/gym" component={Gym} />
          <Route path="/articles" component={ArticlesPage} />
          <Route path="/articlepage" component={ArticlePage} />
          <Route path="/facilities" component={Facilities} />
          <Route path="/lessons" component={Lessons} />
        </Switch>
        <Footer />
      </Router>
    </MainCompDiv>
  );
};

export default Index;
