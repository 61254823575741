import React from 'react';
import {AtriclePage,ArticleHeader,ArticleHeaderText,ArticleHeaderTitle,
  ArticleHeaderByLine,ArticleText,ArticleTextLead,TextLeadHeader,ArticleContent} from '../../Elements/Articles/ArticlePage';

const ArticlePage = () => {
    return (
        <div>
           <AtriclePage>
             <ArticleHeader>
               <ArticleHeaderText>
                 <ArticleHeaderTitle>מה לאכול אחרי אימון</ArticleHeaderTitle>
                 <ArticleHeaderByLine>מלני פנטון</ArticleHeaderByLine>
               </ArticleHeaderText>
             </ArticleHeader>
             <ArticleText>
               <ArticleTextLead> 
                 <TextLeadHeader>
                   רובנו משייכים תכונות מסוימות למאכלים מסוימים, אך לעיתים יש להרחיב יותר את היריעה כדי להבין את התרומה האמיתית של אותם מזונות. לדוגמה,
                    האם ידעתם שזית הוא הפרי של עץ הזית? הוא נחשב לפרי ואחד משבעת המינים, אך שלא כמו פירות אחרים אשר מספקים לגופנו סוכר לצד הנוזלים, הוויטמינים והמינרלים,
                    הזית מספק לגופנו שומן ואם נדייק שמן זית. כמה שומן? האם הוא בריא? למי מומלץ לצרוך אותו
                    </TextLeadHeader>
               </ArticleTextLead>
                 <ArticleContent>
                 נתחיל מבירור קצר: מהם שבעת המינים?

                 חיטה
                 שעורה
                 גפן
                 תאנה
                 רימון
                 זית
                 תמר
                 קל להבחין שחמישה מתוך שבעת המינים הם פירות. בואו נבדוק את סגולותיהם עבורנו.
                 
 
                
                 חיטה
                 
                 חיטה יש מינים רבים. המוכרים ביותר במחוזותינו הם:
                 
                 יטה קשה, היא חיטת הדורום שממנה מיצרים בדרך כלל פסטה
                 יטת כוסמין (כן כן, גם כוסמין הוא סוג של חיטה) ממנה מייצרים לחמים ומיני מאפה
                 יטה רכה, שממנה מייצרים בדרך כלל את הלחם המוכר בתזונה היומיומית שלנו
                 ריקה, שהיא למעשה חיטה ירוקה שעברה תהליך עישון
                 נשים רבים שואלים אותי מה מקורו של הבורגול. אם כך, בורגול הוא מזון המיוצר מגרגרי חיטה מבושלת שיובשה ונגרסה. מאכלים נפוצים בהם משתמשים בבורגול הם הקובה, סלט טבולה ומג'דרה.
                                            
                 תכונות עיקריות של החיטה שרובנו לא יכולים בלעדיה הן כמות יפה של פחמימות (כ 70%), כמות מסוימת של חלבון (כ 12%), ויטמינים ממשפחת B אשר מסייעים בין היתר לספיגה של הפחמימות בגוף, ומינרלים מסוג ברזל, סידן, אשלגן ונתרן.
                 
                 במידה ובחרתם לצרוך חיטה מלאה, תוסיפו על כל היתרונות האלו גם כמות מכובדת של סיבים תזונתיים.
                 
                 שעורה
                 
                 שעורה היא דגן נהדר, אנו מכירים אותה בשמות מעט אחרים כמו גריסים, או גריסי פנינה כאשר הם מקולפים. השעורה עשירה ביותר בסיבים תזונתיים ובנוסף, יש בה ריכוז גבוה של עמילן עמיד שלא מתפרק מהר בגופנו. שילוב של שני אלו מסייעים לשובע לאורך זמן. יש בה אחוז חלבון גבוה משל החיטה ונוגדי חמצון אשר מאיטים תהליכי הזדקנות וחולי בגופנו.
                 
                 יתרונות בולטים נוספים של השעורה נעוצים בתרומתה להפחתת הסיכון להתפתחות של:
                 
                 מחלת הסוכרת
                 יתר לחץ דם
                 מחלת לב כלילית
                 עצירות וסיבוכיה
                 זית
                 
                 הרכיבים של הזית:
                 
                 חומצת שומן חד בלתי רוויה הנקראת אומגה 9, אנטיאוקסידנטים, סידן, ברזל, אשלגן, ויטמין A, E, שמן.
                 
                 שימוש סביר בשמן זית יכול לסייע בשיפור פעילות מעיים ומניעת עצירות.
                 
                 חלק מיתרונותיו הבולטים נקשרים בהפחתת הסיכון לפתח:
                 
                 סרטן
                 מחלות לב וכלי דם
                 דלקות וזיהומים
                 יתר לחץ דם
                 טרשת עורקים כיוון שמוריד את רמות הכולסטרול ה"רע" LDL
                 כמובן שעל אף כל היתרונות המוזכרים, זכרו שכפית שמן זית שווה למנת שמן אחת, או ל 9 זיתים בגודל רגיל, או לכפית טחינה גולמית או לכף סלט חומוס.
                                           
                 צריכה מופרזת של שמן זית או זיתים כל היתרונות הופכים לחסרונות ויכולה להתווסף עליה במשקל כתוצאה ממאזן קלורי חיובי בשל צריכת שמן מוגברת.
              </ArticleContent>
             </ArticleText>
           </AtriclePage>
        </div>
    );
};

export default ArticlePage;