import React, { useEffect, useState } from "react";
import {
  Content,
  Divider,
  Header,
  HeaderOrangeText,
  OuterDiv,
  SidePngYoga,
  TextBox,
} from "../../../Elements/Gym/PictureAndText";
import { Switch, useHistory, useRouteMatch } from "react-router-dom";
import axios from "axios";
import ProtectedRoute from "../../ProtectedRoutes/ProtectedRoute";
import { Button } from "@mui/material";
import image from "../../../Images And Logos/LessonsPng/YogaImage.png";

const YogaBar = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const serverUrl = "textAndImage";
  const appApi = process.env.REACT_APP_MY_API;
  const [barData, setBarData] = useState({
    header: "",
    subHeader: "",
    firstParagraph: "",
    secondParagraph: "",
    image: "",
  });

  return (
    <div>
      <OuterDiv>
        <TextBox>
          <Content>
            <Header>
              סטודיו
              <br />
              <HeaderOrangeText>לגוף ולנפש</HeaderOrangeText>
            </Header>
            <br />
            אם המטרה שלך היא לעצב את הגוף, לבנות מסת שריר,לשפר את סיבולת לב
            ריאה, או למצוא שקט נפשי ושלווה יש לנו מגוון שיעורים שאיתם תוכלי
            להשיג את מטרותיך, עם סביבה משפחתית ואוזן קשבת, צוות המדריכות
            מקצועיות, שיש להם ניסיון וידע רב של שנים בתחום והן יכלו לתת לכם מענה
            לכל המטרות שלכן בתחום הספורט.
            <br />
            <br />
            לחדר כושר יש שתי חדרי סטודיו גדולים ומרווחים עם עיצוב ייחודי ומגוון
            רחב של ציוד מקצועי ואיכותי. האולמות והמדריכים עומדים בקריטריונים
            גבוהים.
            <br />
          </Content>
        </TextBox>
        <Divider></Divider>
        <SidePngYoga style={{ backgroundImage: `url(${image})` }}></SidePngYoga>
      </OuterDiv>
    </div>
  );
};

export default YogaBar;
