import './App.css';
import MainComp from './components/Main/MainComp';

function App() {
  return (
    <div className="App">
      <MainComp />
    </div>
  );
}

export default App;
