import React, { useState, useEffect } from "react";
import {
  Content,
  Divider,
  Header,
  HeaderOrangeText,
  OuterDiv,
  SidePng,
  TextBox,
} from "../../../Elements/Gym/PictureAndText";
import { Route, useHistory, useRouteMatch, Switch } from "react-router-dom";
import { Button } from "@mui/material";
import modernGymImage from "../../../Images And Logos/GymPng/pngwing.png";

const PictureAndText = () => {
  let history = useHistory();
  let { url } = useRouteMatch();
  const serverUrl = "textAndImage";

  useEffect(() => {}, []);

  const displayMod = () => {
    history.push(`${url}/modModernGym`);
  };
  const hideMod = () => {
    history.push(`${url}`);
  };

  return (
    <div>
      <OuterDiv>
        <TextBox>
          <Content>
            <Header>
              חדר כושר
              <br />
              <HeaderOrangeText> מודרני ואיכותי</HeaderOrangeText>
            </Header>
            <br />
            חדר הכושר החדש בהר אדר נבנה ועוצב ברמה המתקדמת ביותר בעולם, תוך
            התייחסות מרבית לצורכי המתאמנים. ציוד הכושר האירובי והשרירי שנרכש
            בשנת 2017, מבית היוצר של חברת לייף פיטנס האמריקנית, הוא האיכותי
            והמתקדם מסוגו בעולם.
            <br />
            <br />
            בצוות המועדון מדריכי חדר כושר וסטודיו מקצועיים אשר הוכשרו והוסמכו על
            ידי בתי הספר למאמנים ומדריכים בארץ, נבחרו בקפידה להדרכה ואימון אצלנו
            . במועדון פועלות תכניות מקצועיות אשר מותאמות אישית לכל מתאמן, תוך
            ליווי צמוד לשיפור הישגיות בתחומים כגון עיצוב וחיטוב, חיזוק, שינוי
            אורח חיים, שמירה על איזון במשקל ופעילות לכל גיל.
            <br />
            <br />
          </Content>
        </TextBox>
        <Divider></Divider>
        <SidePng
          style={{ backgroundImage: `url(${modernGymImage})` }}
        ></SidePng>
      </OuterDiv>
    </div>
  );
};

export default PictureAndText;
