import React, { useEffect, useState } from "react";
import "../../../Styles/StaffCard.css";
import { Button } from "@mui/material";
import annaImage from "../../../Images And Logos/team/Anna.jpeg";
import philipImage from "../../../Images And Logos/team/Unknown1.jpg";
import tomerImage from "../../../Images And Logos/team/Tomer.jpg";
import magenImage from "../../../Images And Logos/team/Unknown3.jpg";
import efratImage from "../../../Images And Logos/team/Unknown2.jpg";
import carmitImage from "../../../Images And Logos/team/Unknown5.jpg";
import michalIMage from "../../../Images And Logos/team/Unknown4.jpg";
import guyImage from "../../../Images And Logos/team/Unknown7.jpg";
import yaelImage from "../../../Images And Logos/team/Unknown6.jpg";
import ronaImage from "../../../Images And Logos/team/Rona.jpg";
import liorImage from "../../../Images And Logos/team/LiorIzhaki.JPG";

const OurStaff = () => {
  const [worker, setWorker] = useState({
    name: "",
    firstParagraph: "",
  });
  const [editDisplay, setEditDisplay] = useState("none");
  const [workers, setWorkers] = useState([
    {
      _id: "פיליפ דובוסרסקי",
      firstParagraph:
        "האימון עבורי הוא אינו רק כלי  לבריאות ,כושר גופני ונראות יותר טובה, אלא הוא בעיקר כלי לפיתוח משמעת עצמית, חוסן מנטלי וחיוניות שבעזרתם ניתן להשיג כל מטרה גם מחוץ לחדר הכושר.",
      secondParagraph:
        "החלטתי להצטרף אל צוות  mount10 כי צוות המאמנים וקהל המתאמנים המדהים והאיכותי הפכו עבורי את האימון למהנה ומתאגר וגרמו לכל חווית האימון להרגיש כמו בית ומשפחה אחת גדולה.",
      image: philipImage,
    },
    {
      _id: "תומר יוסף",
      firstParagraph:
        "אני מאמין שבאמצעות אימונים בחדר כושר ניתן להשיג הרבה יותר מבריאות ומראה, באמצעות אימונים והתמדה ניתן לפתח חוסן מנטלי, משמעת עצמית וכלים שעוזרים להתמודד עם קשיים יום יומיים .",
      secondParagraph:
        "אני אוהב להדריך בחדר כושר בכלל ובהר אדר בפרט בגלל שזה חדר כושר קטן ומשפחתי ואני יכול לשים לב לכל מתאמן ולעזור בצורה הרבה יותר מפורטת מאשר חדרי כושר גדולים יותר.",
      image: tomerImage,
    },
    {
      _id: "מגן הר סיני",
      firstParagraph:
        "ספורט בשבילי זה חלק בלתי נפרד מאז שאני זוכר את עצמי הורי אמרו לי שלא הלכתי עד גיל 3 והצעדים הראשונים בריצה מאז לא הפסקתי לרוץ ובשבילי זה מקום שקט ופורק לחץ .",
      secondParagraph:
        "אני אחד שאוהב להציב מטרות בחיים אני חושב שבשביל זה נולדנו  לא משנה המרחק הזמן או תוצאה העיקר להמשיך קדימה.",
      image: magenImage,
    },
    {
      _id: " אפרת אביבי",
      firstParagraph:
        "אימוני כוח בשבילי זה להיות חזקה ובריאה, פיזית ונפשית, זה נותן לי חוסן מנטלי, ומסייע לי להתמודד עם אתגרים שבאימונים .",
      secondParagraph:
        "חשוב לי שהמתאמנים שלי לא ירגישו לבד, וידעו ושתמיד יש אוזן קשבת, ושיש על מי לסמוך, אני כאן בשבילם. המתאמנים שלי זה משפחה.",
      image: efratImage,
    },
    {
      _id: "כרמית ",
      firstParagraph:
        "היי אני כרמית מדריכת פילאטיס מכשירים, כושר ומטפלת בתנועה תנועה בשבילי היא דרך חיים, תנועה על כל גווניה היא הדרך המהירה והנעימה ביותר לריפוי ואיזון הגוף והנפש.",
      secondParagraph: "\n",
      image: carmitImage,
    },
    {
      _id: " מיכל מתתיהו",
      firstParagraph:
        "מאז ומתמיד היה לי חיבור וזיקה לאורח חיים בריא ולהבנת הקשר בין הגוף לנפש.",
      secondParagraph:
        "התמורה כי גדולה שלי מהמקצוע הזה היא לראות את האושר שאני גורמת לאנשים, ההשראה והמודעות שהם מקבלים לחיות חיים בריאים ופעילים יותר והשינוי שזה גורם להם בחייהם.",
      image: michalIMage,
    },
    {
      _id: "גיא קמחי",
      firstParagraph:
        "אני מאמין שעם התמדה והתקדמות לא פוסקת אנו יכולים להשיג את מטרותינו. אני מלווה את המתאמנים שלי להגיע למטרותיהם הפיזיות והנפשיות. המתאמנים שלי הם חברים ומשפחה .",
      secondParagraph: "דרך הספורט אני מוביל אנשים להיות מצליחים ושקדנים.",
      image: guyImage,
    },
    {
      _id: "יעל כהן",
      firstParagraph:
        "מכל זהותי אני גם מורה ליוגה, אשטנגה ויניאסה, ויני יוגה . דרך העבודה עם הגוף, הנשימה, והמדיטציה גיליתי את היכולת להיות מודעת לתחושותיי, רגשותיי ומחשבותיי ולהיות נוכחת ברגע ההווה.",
      secondParagraph:
        "בהמשך לימודי בפילוסופיה של היוגה ובמדיטציה נוכחתי לדעת עד כמה הידע הזה מאפשר רובד נוסף לרווחה נפשית והכרתית ולגדילה במודעות.",
      image: yaelImage,
    },
    {
      _id: " אנה אלייה כץ",
      firstParagraph:
        "בוגרת תואר ראשון בחינוך גופני וספורט טיפולי. מדריכת  פילאטיס, ספינינג, קיקבוקס ועד. עבורי כושר זה אושר וטעם החיים !",
      secondParagraph: "\n",
      image: annaImage,
    },
    {
      _id: " רונה צורף",
      firstParagraph:
        "מורה להאתה יוגה ולמיינדפולנס, מטפלת ביוגה תרפיה ובקערות טיבטיות. אני בת 55 וחיה בדרך היוגה מזה שלושים שנה. זו דרך של התמסרות בתירגול יומיומי של מדיטציות ותירגול פיזי וגם דרך פילוסופית ורוחנית, ובכל יום ישנה תמורה לטובה לי ולסביבתי! מזמינה אתכם להצטרף וללכת איתי בדרך.",
      secondParagraph: "\n",
      image: ronaImage,
    },
    {
      _id: "ליאור יצחקי",
      firstParagraph:
        "שמתי לעצמי מטרה להנחיל אורח חיים ספורטיבי לכמה שיותר אנשים . מתעסק בעיקר באימונים אישיים התמחות בגיל המבוגר. בשנים האחרונות נכנסתי לתחום הטריאטלון. איש ברזל. כל מי שעוסק בספורט באופן קבוע יחייה  טוב יותר ויהיה בריא יותר.",
      secondParagraph: "\n",
      image: liorImage,
    },
  ]);

  const workerObj = workers.map((worker) => {
    return (
      <div className="worker-card">
        <div className="worker-card-body">
          <figure className="image-block">
            <img className="worker-image" src={worker.image} alt="not found" />
            <figcaption className="worker-details">
              <h3 className="details-header">
                <Button
                  style={{ display: `${editDisplay}` }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setWorker(worker);
                  }}
                >
                  Success
                </Button>
                {worker._id}
                <div className="read-more">...עוד עליי</div>
              </h3>
              <div className="details-text">
                {worker.firstParagraph}
                <br />
                {worker.secondParagraph}
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    );
  });
  return (
    <div className="staff-layout">
      <div className="cards-layout">{workerObj}</div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default OurStaff;
